import React, { useEffect } from 'react';
import { route, map } from 'navi';
import { Helmet } from 'react-navi-helmet-async';
import List from '@2022-mom-web/components/src/list';
import Card from '@2022-mom-web/components/src/card';
import Photo from '@2022-mom-web/components/src/photo';
import Instagram from '@2022-mom-web/components/src/instagram';
import Twitter from '@2022-mom-web/components/src/twitter';
import Mysta from '@2022-mom-web/components/src/mysta';
import Navigation from '@2022-mom-web/components/src/navigation';
import Entry from '@2022-mom-web/components/src/types/entry';
import {Link, useCurrentRoute} from 'react-navi';
import { list } from '../../json/entry.json';
import { ReactComponent as Logo } from '../../assets/mrofmr.svg';
import classNames from "classnames";
import loadingImage from '../../assets/loading.png';
import Voted from "@2022-mom-web/components/src/voted";

let profiles = list.map((entry: any) => ({
  id: entry.id,
  getPage: map( () => {
    return route({
      title: `${entry.name} | ${process.env.REACT_APP_WEBSITE_NAME}`,
      getView: () => {
        return <App entry={entry} />;
      },
    });
  }),
}));

export default profiles;

const App = (props: { entry: Entry }) => {
  useEffect(() => fancybox(), []);
  const {
    url: { query },
  } = useCurrentRoute();
  const src =
    props.entry.images.length > 0
      ? `/image/entries/${props.entry.id}/${props.entry.images[0]}.jpg`
      : loadingImage;
  const thumbnails = props.entry.images.slice(0, props.entry.images.length - 1);
  return <div id="profiles">
    <Helmet>
      <meta content={`${props.entry.name} | ${process.env.REACT_APP_WEBSITE_NAME}`} property="og:title" />
      <meta content={`${process.env.REACT_APP_URL}${src}`} property="og:image" />
      <meta content={`${process.env.REACT_APP_URL}/profile/${props.entry.id}`} property="og:url" />
    </Helmet>
    <Voted {...query} src={src} />
    <nav className="navbar is-black" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link className="navbar-item" href="/">
          <Logo />
        </Link>
      </div>
    </nav>
    <Navigation
        additional={
          <li>
            <a href="https://missofmiss.jp/" target="_blank">
              <span className="icon is-small">
                <i className="fas fa-external-link-alt" aria-hidden="true" />
              </span>
              <span>MISS OF MISS</span>
            </a>
          </li>
        }
    />
    <section className="section as-profile is-mrofmr">
      <div className="container">
        <div className="columns is-variable is-8 ">
          <div className="column mb-3">
            <Photo href={src} src={src} loadingImage={loadingImage} />
            {thumbnails.length > 0 && (
                <div className="columns is-mobile is-multiline is-variable as-profile-photo-list">
                  {thumbnails.map(thumbnail => (
                      <div key={thumbnail} className="column is-one-fifth">
                        <Photo
                            onBackground
                            loadingImage={loadingImage}
                            href={`/image/entries/${props.entry.id}/${props.entry.images[thumbnail]}.jpg`}
                            src={`/image/entries/${props.entry.id}/${props.entry.images[thumbnail]}.jpg`}
                        />
                      </div>
                  ))}
                </div>
            )}
          </div>
          <div className="column">
            {props.entry.finalist && <p className="tag is-link is-light mb-2">ファイナリスト</p>}
            <div className="columns is-variable is-size-3 is-2 is-wrap">
              <div className="column is-narrow" style={{ maxWidth: '100%' }}>
                <h1>{props.entry.name}</h1>
              </div>
              <div className="column is-narrow">
                <div className="columns is-mobile is-variable is-size-3 is-2 is-wrap">
                  {props.entry.instagram && (
                      <div className="column is-narrow">
                        <Instagram
                            href={`https://instagram.com/${props.entry.instagram}`}
                            target="_blank"
                            style={{ marginTop: 3 }}
                        />
                      </div>
                  )}
                  {props.entry.twitter && (
                      <div className="column is-narrow">
                        <Twitter
                            href={`https://twitter.com/${props.entry.twitter}`}
                            target="_blank"
                            style={{ marginTop: 3 }}
                        />
                      </div>
                  )}
                  {props.entry.mysta && (
                      <div className="column is-narrow">
                        <Mysta
                            href={`https://appweb.mysta.tv/web/share/profile?id=${props.entry.mysta}\``}
                            target="_blank"
                            style={{ marginTop: 3 }}
                        />
                      </div>
                  )}
                </div>
              </div>
            </div>
            {/* Detail */}
            <div className="mb-3">
              <p className="has-text-link">大学・学部・学年</p>
              <p className="is-size-5">
                {props.entry.college} {props.entry.department} {props.entry.grade}年
              </p>
            </div>
            {props.entry.birthday && (
                <div className="mb-3">
                  <p className="has-text-link">生年月日</p>
                  <p className="is-size-5">{props.entry.birthday}</p>
                </div>
            )}
            {props.entry.hometown && (
                <div className="mb-3">
                  <p className="has-text-link">出身地</p>
                  <p className="is-size-5">{props.entry.hometown}</p>
                </div>
            )}
            {/*
            <div className="has-text-centered mb-3">
              <a
                  className="button has-crown is-medium is-fullwidth"
                  href={`${process.env.REACT_APP_VOTE_URL}/${props.entry.id}`}
              >
                <span>投票する</span>
              </a>
            </div>
            */}
            {props.entry.hobby && (
                <div className="mb-3">
                  <p className="has-text-grey">趣味</p>
                  <p>{props.entry.hobby}</p>
                </div>
            )}
            {props.entry.skill && (
                <div className="mb-3">
                  <p className="has-text-grey">特技</p>
                  <p>{props.entry.skill}</p>
                </div>
            )}
            {props.entry.magazine && (
                <div className="mb-3">
                  <p className="has-text-grey">好きな雑誌</p>
                  <p>{props.entry.magazine}</p>
                </div>
            )}
            {props.entry.dream && (
                <div className="mb-3">
                  <p className="has-text-grey">将来の夢</p>
                  <p>{props.entry.dream}</p>
                </div>
            )}
            {props.entry.enthusiasm && (
                <div className="mb-3">
                  <p className="has-text-grey">意気込み</p>
                  <p>{props.entry.enthusiasm}</p>
                </div>
            )}
          </div>
        </div>
      </div>
    </section>
    {/*
        Entries
      */}
    <section id="entries" className="section">
      <div className="container">
        <div className="mb-6">
          <h1 className="title as-section-title">Entries</h1>
          <h2 className="subtitle">出場者一覧</h2>
        </div>
        <div className="columns is-multiline is-mobile mb-6">
          <List list={list} size={4}>
            {(list: Entry[]) =>
                list.map((item: Entry, index: number) => (
                    <div
                        className={classNames('column is-3-desktop is-4-tablet is-6-mobile', {
                          'is-hidden-tablet-only': index === 3,
                        })}
                        key={item.id}
                    >
                      <Card
                          className="is-missofmiss"
                          loadingImage={loadingImage}
                          href={`/profiles/${item.id}`}
                          entry={item}
                      />
                    </div>
                ))
            }
          </List>
        </div>
        <div className="has-text-centered">
          <Link className="button has-crown is-medium" href={'/entries'}>
            <span>他の出場者を見る</span>
          </Link>
        </div>
      </div>
    </section>
    <footer className="footer has-background-black is-padding-bottom-3">
      <div className="content has-text-centered">
        <Logo width={150} />
        <p className="is-size-7 mt-3">Copyright © 2021 株式会社エイジ・エンタテインメント All Rights Reserved.</p>
      </div>
      <a className="button is-size-7 is-light is-dark" href="mailto:inquiry@missofmiss.jp">
          <span className="icon">
            <i className="far fa-envelope" />
          </span>
        <span>お問い合わせ</span>
      </a>
    </footer>
  </div>;

  function fancybox() {
    try {
      const global: any = window;
      const query: any = global.$('a[data-fancybox]');
      query &&
        query.fancybox({
          loop: true,
          infobar: false,
          buttons: ['close'],
        });
    } catch {}
  }
};
