import React from 'react';

interface TimelineProps {}

const Timeline: React.FC<TimelineProps & React.HTMLAttributes<HTMLDivElement>> = (props) => (
  <div className="timeline">
    <table className="table as-timeline">
      <tbody>
        <tr>
          <td className="is-narrow">
            <svg width="20px">
              <rect x="6.5" y="17" width="10" height="10" rx="11" ry="11" stroke="white" strokeWidth="1" fill="white" />
              <line x1="12" y1="23" x2="10" y2="520" stroke="white" strokeWidth="1" />
            </svg>
          </td>
          <td className="centered is-padding-bottom-3">
            <div>
              <p className="is-size-4">全国予選</p>
              <p>2022/02/14(月)～2022/02/20(日)</p>
            </div>
          </td>
        </tr>
        <tr>
          <td className="is-narrow">
            <svg width="20px">
              <rect x="5.5" y="17" width="10" height="10" rx="11" ry="11" stroke="white" strokeWidth="1" fill="white" />
            </svg>
          </td>
          <td className="centered">
            <div>
              <p className="is-size-4">決勝</p>
              <p>2022/02/28(月)～2022/03/06(日)</p>
            </div>
          </td>
        </tr>
        <tr>
          <td className="is-narrow">
            <svg width="20px">
              <rect x="5.5" y="17" width="10" height="10" rx="11" ry="11" stroke="white" strokeWidth="1" fill="white" />
            </svg>
          </td>
          <td className="centered">
            <div>
              <p className="is-size-4">表彰式</p>
              <p>
                2022/03/09(水)
                <br />
                @EX THEATER ROPPONGI
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default Timeline;
