import React from 'react';
import classNames from 'classnames';
import {route, mount, compose} from 'navi';
import { Link } from 'react-navi';
import {Helmet} from 'react-navi-helmet-async';
import History from '@2022-mom-web/components/src/history';
import Schedule from '@2022-mom-web/components/src/schedule';
import Navigation from '@2022-mom-web/components/src/navigation';
import Announcement from '@2022-mom-web/components/src/announcement';
import List from '@2022-mom-web/components/src/list';
import Card from '@2022-mom-web/components/src/card';
import Entry from '@2022-mom-web/components/src/types/entry';
import {MediaBox, MediaBoxes} from "@2022-mom-web/components/src/mediabox";
import history from '../../json/history.json';
import {ReactComponent as Blur} from '../../assets/blur.svg';
import {ReactComponent as Logo} from '../../assets/logo.svg';
import {ReactComponent as LogoTop} from '../../assets/logo_top.svg';
import {ReactComponent as Logo2021} from '../../assets/logo_2021.svg';
import {ReactComponent as CanCam} from '../../assets/cancam.svg';
import {ReactComponent as Datetime} from "../../assets/datetime.svg";
import {ReactComponent as ACNAL} from "../../assets/ACNAL_MANARA_logo.svg";
import loadingImage from '../../assets/loading.png';
import { list } from '../../json/entry.json';

export default compose(
  mount({
    '/': route({
      getView: ({context}) => (
        <>
          <Helmet>
            <meta content={`${process.env.REACT_APP_URL}`} property="og:url"/>
            <meta content={`${process.env.REACT_APP_OGP_URL}`} property="og:image"/>
          </Helmet>
          <App/>
        </>
      ),
      title: process.env.REACT_APP_WEBSITE_NAME,
    }),
  }),
);

const App = () => {
  return (
    <div id="top">
      <section className="hero is-fullheight is-black">
        <div className="hero-cover">
          <figure className="blur">
            <Blur/>
          </figure>
        </div>
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title is-spaced mb-6">
              <LogoTop style={{maxWidth: 600, maxHeight: 210}}/>
            </h1>
            <p className="subtitle">
              <Datetime />
            </p>
          </div>
        </div>
        <div className="hero-foot is-relative has-text-centered">

          <Navigation
            additional={
              <li>
                <a href="https://missofmiss.jp/" target="_blank">
                  <span className="icon is-small">
                    <i className="fas fa-external-link-alt" aria-hidden="true"/>
                  </span>
                  <span>MISS OF MISS</span>
                </a>
              </li>
            }
          />
        </div>
      </section>
      {/*
        Award
      */}
      <section id="award" className="section has-background-gold-stripe">
        <div className="container">
          <div className="mb-6">
            <h1 className="title as-section-title">AWARD</h1>
            <h2 className="subtitle">受賞者一覧</h2>
          </div>
          {/* グランプリ */}
          <div className="mb-6">
            <h1 className="title has-text-link is-size-3 as-section-title has-text-gold has-text-weight-bold">グランプリ</h1>
            <h2 className="subtitle"/>
          </div>
          <div className="columns is-centered is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${45}`}
                entry={{
                  "id": 45,
                  "mysta": "",
                  "showroom": "",
                  "college": "中央大学",
                  "name": "髙尾 昇吾",
                  "furigana": "たかお しょうご",
                  "hometown": "広島県",
                  "grade": 3,
                  "department": "商学部",
                  "twitter": "mr5chuo2021",
                  "instagram": "shogo_takao",
                  "hobby": "読書",
                  "skill": "サッカー",
                  "dream": "かっこいい大人になる",
                  "enthusiasm": "頑張ります！",
                  "circle": "サッカーサークル(中央大学体同連フースバルクラブ)",
                  "magazine": "",
                  "birthday": "2000/04/16",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
          </div>
          {/* 準グランプリ */}
          <div className="mb-6">
            <h1 className="title is-size-5 as-section-title has-text-weight-bold">準グランプリ</h1>
            <h2 className="subtitle"/>
          </div>
          <div className="columns is-centered is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${33}`}
                entry={
                  {
                    "id": 33,
                    "mysta": "",
                    "showroom": "",
                    "college": "千葉大学",
                    "name": "金澤 光平",
                    "furigana": "かなざわ こうへい",
                    "hometown": "千葉県",
                    "grade": 2,
                    "department": "教育学部",
                    "twitter": "chibamr2021_06",
                    "instagram": "chibamr_202106",
                    "hobby": "音楽演奏、動画鑑賞、フィギュアスケート",
                    "skill": "ピアノ、サックス",
                    "dream": "人に勇気を与える仕事",
                    "enthusiasm": "今まで応援してくださった全ての方に感謝の気持ちを込めて、精一杯頑張ります！！",
                    "circle": "吹奏楽団、ディズニーサークル",
                    "magazine": "Ray",
                    "birthday": "2001/07/09",
                    "images": [1],
                    "finalist": true,
                  }
                }
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${38}`}
                entry={{
                  "id": 38,
                  "mysta": "",
                  "showroom": "",
                  "college": "青山学院大学",
                  "name": "山根 亮",
                  "furigana": "やまね りょう",
                  "hometown": "神奈川県",
                  "grade": 2,
                  "department": "理工学部",
                  "twitter": "agu21_mr4",
                  "instagram": "agu21_mr4",
                  "hobby": "サッカー、ドライブ、アマプラ",
                  "skill": "サッカー、演技、Dynamite(Dance)",
                  "dream": "俳優、モデル",
                  "enthusiasm": "青山ミスコンを通して知ってくれた皆さんに、これからも応援して頂けるよう頑張りますので、引き続き応援よろしくお願いします！",
                  "circle": "ソニッカーズ",
                  "magazine": "CanCam",
                  "birthday": "2001/12/19",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
          </div>
          {/* 審査員特別賞 */}
          <div className="mb-6">
            <h1 className="title is-size-5 as-section-title has-text-weight-bold">審査員特別賞</h1>
            <h2 className="subtitle"/>
          </div>
          <div className="columns is-centered is-multiline is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${8}`}
                entry={{
                  "id": 8,
                  "mysta": "",
                  "showroom": "",
                  "college": "学習院大学",
                  "name": "宮川 拓実",
                  "furigana": "みやがわ たくみ",
                  "hometown": "神奈川県",
                  "grade": 1,
                  "department": "理学部",
                  "twitter": "mr_gu21no2",
                  "instagram": "takumi_miyagawa",
                  "hobby": "筋トレ、音楽",
                  "skill": "サッカー",
                  "dream": "芸能界で有名になること",
                  "enthusiasm": "グランプリを目指して頑張ります。",
                  "circle": "",
                  "magazine": "",
                  "birthday": "2001/12/03",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${36}`}
                entry={{
                  "id": 36,
                  "mysta": "",
                  "showroom": "",
                  "college": "横浜国立大学",
                  "name": "遠藤 響生",
                  "furigana": "えんどう ひびき",
                  "hometown": "山梨県",
                  "grade": "院1",
                  "department": "先進実践学環",
                  "twitter": "mrynu2021_01",
                  "instagram": "mrynu2021_01",
                  "hobby": "大喜利、サウナ、ビール",
                  "skill": "謎解き、ダンス、利きビール、歯医者の音マネ",
                  "dream": "暖かな家庭を持つお金持ちお父さん",
                  "enthusiasm": "最善は尽くします、最善は。",
                  "circle": "ストリートダンスサークルR3ude",
                  "magazine": "CanCam",
                  "birthday": "1997/11/28",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${7}`}
                entry={{
                  "id": 7,
                  "mysta": "",
                  "showroom": "",
                  "college": "千葉大学",
                  "name": "森 洸人",
                  "furigana": "もり ひろと",
                  "hometown": "埼玉県",
                  "grade": 1,
                  "department": "国際教養学部",
                  "twitter": "chibamr2021_02",
                  "instagram": "chibamr2021_no2",
                  "hobby": "筋トレ、散歩、音楽を聴くこと、読書、ゲーム、漫画、アニメ",
                  "skill": "剣道、ダジャレ",
                  "dream": "官僚",
                  "enthusiasm": "全力でグランプリ目指して楽しみます！",
                  "circle": "バスケ、バレー、テニス",
                  "magazine": "ニュートン",
                  "birthday": "2002/06/17",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
          </div>
          {/* ACNAL賞 */}
          <div className="columns is-centered is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title has-text-weight-bold"><br
                  className="is-hidden-tablet"/>ACNAL賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${42}`}
                entry={{
                  "id": 42,
                  "mysta": "",
                  "showroom": "",
                  "college": "専修大学",
                  "name": "池田 吏久斗",
                  "furigana": "いけだ りくと",
                  "hometown": "福井県",
                  "grade": 3,
                  "department": "経済学部",
                  "twitter": "",
                  "instagram": "senshu21_mr1",
                  "hobby": "サウナ、ドライブ",
                  "skill": "ダンス",
                  "dream": "",
                  "enthusiasm": "日本一獲ります",
                  "circle": "キックボクシング部",
                  "magazine": "メンズノンノ",
                  "birthday": "2000/10/28",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
          </div>
          {/* アナトレ賞/テレビ朝日アスク賞/ANNIVERSAIRE賞 */}
          <div className="columns is-centered is-multiline is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title has-text-weight-bold"><br
                  className="is-hidden-tablet"/>フジテレビアナトレ賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${38}`}
                entry={{
                  "id": 38,
                  "mysta": "",
                  "showroom": "",
                  "college": "青山学院大学",
                  "name": "山根 亮",
                  "furigana": "やまね りょう",
                  "hometown": "神奈川県",
                  "grade": 2,
                  "department": "理工学部",
                  "twitter": "agu21_mr4",
                  "instagram": "agu21_mr4",
                  "hobby": "サッカー、ドライブ、アマプラ",
                  "skill": "サッカー、演技、Dynamite(Dance)",
                  "dream": "俳優、モデル",
                  "enthusiasm": "青山ミスコンを通して知ってくれた皆さんに、これからも応援して頂けるよう頑張りますので、引き続き応援よろしくお願いします！",
                  "circle": "ソニッカーズ",
                  "magazine": "CanCam",
                  "birthday": "2001/12/19",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title "><br className="is-hidden-tablet"/>ANNIVERSAIRE賞
                </h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${47}`}
                entry={{
                  "id": 47,
                  "mysta": "",
                  "showroom": "",
                  "college": "立教大学",
                  "name": "鈴木 大輝",
                  "furigana": "すずき だいき",
                  "hometown": "東京都",
                  "grade": 4,
                  "department": "経営学部",
                  "twitter": "daikisuzuki0",
                  "instagram": "daikisuzuki0",
                  "hobby": "音楽、映画鑑賞、読書、スノボ、麻雀、服",
                  "skill": "よくよく考えたらタイピングの速さで誰かに負けたことがないですね",
                  "dream": "クリエイティブディレクター、自分のクリエイティブチームを作ること。",
                  "enthusiasm": "ミスター立教コンテストの時にも信念としていた『自分のかっこいいと思うものを発信して、より多くの人にかっこいいと思ってもらい、誰かのきっかけや感動を作る』ということを目標に活動します。\nまた、大学内のコンテストよりも規模が大きくなるので、自分の音楽や動画などの作品を常に発信し続けて、クリエイティブに活動したいと考えています。",
                  "circle": "学園祭運営委員会",
                  "magazine": "popeye",
                  "birthday": "2000/02/26",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
          </div>
          {/* CanCam賞/mysta賞/モデルプレス賞 */}
          <div className="columns is-centered is-multiline is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title is-no-uppercase">CanCam賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${45}`}
                entry={{
                  "id": 45,
                  "mysta": "",
                  "showroom": "",
                  "college": "中央大学",
                  "name": "髙尾 昇吾",
                  "furigana": "たかお しょうご",
                  "hometown": "広島県",
                  "grade": 3,
                  "department": "商学部",
                  "twitter": "mr5chuo2021",
                  "instagram": "shogo_takao",
                  "hobby": "読書",
                  "skill": "サッカー",
                  "dream": "かっこいい大人になる",
                  "enthusiasm": "頑張ります！",
                  "circle": "サッカーサークル(中央大学体同連フースバルクラブ)",
                  "magazine": "",
                  "birthday": "2000/04/16",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile is-no-uppercase as-section-title">mysta賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${33}`}
                entry={{
                  "id": 33,
                  "mysta": "",
                  "showroom": "",
                  "college": "千葉大学",
                  "name": "金澤 光平",
                  "furigana": "かなざわ こうへい",
                  "hometown": "千葉県",
                  "grade": 2,
                  "department": "教育学部",
                  "twitter": "chibamr2021_06",
                  "instagram": "chibamr_202106",
                  "hobby": "音楽演奏、動画鑑賞、フィギュアスケート",
                  "skill": "ピアノ、サックス",
                  "dream": "人に勇気を与える仕事",
                  "enthusiasm": "今まで応援してくださった全ての方に感謝の気持ちを込めて、精一杯頑張ります！！",
                  "circle": "吹奏楽団、ディズニーサークル",
                  "magazine": "Ray",
                  "birthday": "2001/07/09",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title is-no-uppercase">モデルプレス賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${49}`}
                entry={{
                  "id": 49,
                  "mysta": "",
                  "showroom": "",
                  "college": "早稲田大学",
                  "name": "谷内　拳",
                  "furigana": "たにうち　けん",
                  "hometown": "大阪府",
                  "grade": 2,
                  "department": "文学部",
                  "twitter": "kensfitnesslife",
                  "instagram": "ken_t14",
                  "hobby": "筋トレ、料理、読書",
                  "skill": "英語、減量",
                  "dream": "日本人を皆健康にすること",
                  "enthusiasm": "トレーニングを始め全てが変わり、ミスター早稲田になった。行けるところまでいきます。",
                  "circle": "早大バーベルクラブ",
                  "magazine": "Muscle&Fitness、月刊ボディビルディング",
                  "birthday": "2000/10/14",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
          </div>
          {/* CanCam賞/mysta賞 */}
          <div className="columns is-centered is-multiline is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile is-no-uppercase as-section-title">O0u賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${45}`}
                entry={{
                  "id": 45,
                  "mysta": "",
                  "showroom": "",
                  "college": "中央大学",
                  "name": "髙尾 昇吾",
                  "furigana": "たかお しょうご",
                  "hometown": "広島県",
                  "grade": 3,
                  "department": "商学部",
                  "twitter": "mr5chuo2021",
                  "instagram": "shogo_takao",
                  "hobby": "読書",
                  "skill": "サッカー",
                  "dream": "かっこいい大人になる",
                  "enthusiasm": "頑張ります！",
                  "circle": "サッカーサークル(中央大学体同連フースバルクラブ)",
                  "magazine": "",
                  "birthday": "2000/04/16",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title is-no-uppercase">RAGEBLUE賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${30}`}
                entry={{
                  "id": 30,
                  "mysta": "",
                  "showroom": "",
                  "college": "中央大学",
                  "name": "大塚 悠平",
                  "furigana": "おおつか ゆうへい",
                  "hometown": "東京都",
                  "grade": 3,
                  "department": "商学部",
                  "twitter": "mr3chuo2021",
                  "instagram": "yuhei_otsuka",
                  "hobby": "温泉巡り",
                  "skill": "フラワーアレンジメント",
                  "dream": "未定",
                  "enthusiasm": "頑張ります！！",
                  "circle": "フットサルサークル",
                  "magazine": "camcan",
                  "birthday": "2000/08/17",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
          </div>


          {/* CanCam賞/mysta賞 */}
          <div className="columns is-centered is-multiline is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title is-no-uppercase">アルプロン賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${20}`}
                entry={{
                  "id": 20,
                  "mysta": "",
                  "showroom": "",
                  "college": "慶應義塾大学",
                  "name": "保木 魁人",
                  "furigana": "やすき かいと",
                  "hometown": "千葉県",
                  "grade": 3,
                  "department": "総合政策学部",
                  "twitter": "keioboy_no4",
                  "instagram": "kaito_yasuki_",
                  "hobby": "食事、ゴルフ",
                  "skill": "スポーツ",
                  "dream": "野球指導に携わりたい。",
                  "enthusiasm": "これまで応援してくださった方々に対して、さらに成長していく姿をお見せできるように頑張りたいなと思います。",
                  "circle": "",
                  "magazine": "",
                  "birthday": "2000/06/18",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile is-no-uppercase as-section-title">Bellcida賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${21}`}
                entry={{
                  "id": 21,
                  "mysta": "",
                  "showroom": "",
                  "college": "國學院大學",
                  "name": "國元 祐聖",
                  "furigana": "くにもと ゆうま",
                  "hometown": "千葉県",
                  "grade": 2,
                  "department": "法学部",
                  "twitter": "mrkokudai21_2",
                  "instagram": "mr.kokudai21_2",
                  "hobby": "筋トレ、漫画を読むこと",
                  "skill": "すぐに起きれること",
                  "dream": "たくさんの人と係わる仕事に就くこと",
                  "enthusiasm": "このような素敵なコンテストに出場できることに感謝致します。ほかの出場者の輝きに負けないように、楽しみながら活動していきたいと思います。出場するからには負けたくないので、全力で頑張ります。",
                  "circle": "サッカー",
                  "magazine": "fudge",
                  "birthday": "2001/12/30",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title is-no-uppercase">BUIK FARM賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${11}`}
                entry={{
                  "id": 11,
                  "mysta": "",
                  "showroom": "",
                  "college": "龍谷大学",
                  "name": "我如古 雅也",
                  "furigana": "かねこ まさや",
                  "hometown": "兵庫県",
                  "grade": 3,
                  "department": "社会学部",
                  "twitter": "rf99_mr02",
                  "instagram": "CWAk_sfPYjm",
                  "hobby": "読書",
                  "skill": "逆立ちできます！",
                  "dream": "人に勇気や元気を与えれる存在になりたい！",
                  "enthusiasm": "やるからにはてっぺん取ります！",
                  "circle": "",
                  "magazine": "CanCam",
                  "birthday": "2000/04/30",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
          </div>

          {/* La Crème au beurre賞/Now me. by RUNWAY cannel賞/Heather賞 */}
          <div className="columns is-centered is-multiline is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title is-no-uppercase">La Crème <br/>au
                  beurre賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${17}`}
                entry={{
                  "id": 17,
                  "mysta": "",
                  "showroom": "",
                  "college": "東京工業大学",
                  "name": "石川 新",
                  "furigana": "いしかわ しん",
                  "hometown": "神奈川県",
                  "grade": 4,
                  "department": "環境・社会理工学院 融合理工学系",
                  "twitter": "IshikawaShin529",
                  "instagram": "mr_titech_2021_no.1",
                  "hobby": "旅、ダンス、カラオケ",
                  "skill": "ダンス、けん玉、動画編集",
                  "dream": "国立競技場でコンサートをすること！",
                  "enthusiasm": "自分にとっては少し背伸びした挑戦になるかもしれませんが、グランプリ目指して最後まで頑張ります！\n今年度最後の大きなコンテストを一緒に走り抜けてくれたら嬉しいです！\n応援よろしくお願いいたします！",
                  "circle": "Attic Lab、TechPackers、Taki Plaza Gardener",
                  "magazine": "MEN'S NON-NO、men's FUDGE",
                  "birthday": "1998/05/29",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
          </div>
          {/* MISCOLLE賞 */}
          <div className="columns is-centered is-multiline is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title is-no-uppercase">MISCOLLE賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${48}`}
                entry={{
                  "id": 48,
                  "mysta": "",
                  "showroom": "",
                  "college": "松山大学",
                  "name": "兵頭 清",
                  "furigana": "ひょうどう きよ",
                  "hometown": "愛媛県",
                  "grade": 1,
                  "department": "人文学部",
                  "twitter": "",
                  "instagram": "kyozuu_o9",
                  "hobby": "音楽、バイク",
                  "skill": "英語",
                  "dream": "世界旅行",
                  "enthusiasm": "準グランプリ悔しかったのでリベンジです",
                  "circle": "ボクシング部",
                  "magazine": "",
                  "birthday": "2001/09/15",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title is-no-uppercase">MISCOLLE賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${40}`}
                entry={{
                  "id": 40,
                  "mysta": "",
                  "showroom": "",
                  "college": "成城大学",
                  "name": "清水 元太",
                  "furigana": "しみず げんた",
                  "hometown": "神奈川県",
                  "grade": 2,
                  "department": "社会イノベーション学部",
                  "twitter": "mrseijo2021_2",
                  "instagram": "shimizugenta__",
                  "hobby": "バレーボール、ギター、歌、服",
                  "skill": "料理",
                  "dream": "俳優、歌手",
                  "enthusiasm": "出場の機会をいただけたことに感謝しています。将来の糧になるよう、全力で臨みます！",
                  "circle": "",
                  "magazine": "men's FUDGE、MEN'S NON-NO",
                  "birthday": "2001/11/20",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title is-no-uppercase">MISCOLLE賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${22}`}
                entry={{
                  "id": 22,
                  "mysta": "",
                  "showroom": "",
                  "college": "名古屋市立大学",
                  "name": "林原 健将",
                  "furigana": "はやしはら けんしょう",
                  "hometown": "愛知県",
                  "grade": 3,
                  "department": "薬学部",
                  "twitter": "ncumr_21_03",
                  "instagram": "kensho.h_42",
                  "hobby": "ドライブ、古着巡り",
                  "skill": "歌うこと",
                  "dream": "無病息災",
                  "enthusiasm": "貴重な機会なので、最後の最後まで自分らしく頑張り、そして楽しみたいです！！",
                  "circle": "軽音、スキー",
                  "magazine": "MEN'S NON-NO",
                  "birthday": "1999/04/02",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
          </div>
        </div>
      </section>

      {/*
        Entries
      */}
      <section id="entries" className="section">
        <div className="container">
          <div className="mb-6">
            {/*
            <h1 className="title as-section-title">ENTRIES</h1>
            <h2 className="subtitle">出場者一覧</h2>
            */}
            <h1 className="title as-section-title">FINALISTS</h1>
            <h2 className="subtitle">ファイナリスト</h2>
          </div>
          <div className="columns is-multiline is-mobile mb-6">
            <List list={list} size={22} finalist>
              {(list: Entry[]) =>
                  list.map((item: Entry, index: number) => (
                      <div
                          className={classNames('column is-3-desktop is-4-tablet is-6-mobile')}
                          key={item.id}
                      >
                        <Card
                            className="is-mrofmr"
                            loadingImage={loadingImage}
                            href={`/profiles/${item.id}`}
                            entry={item}
                        />
                      </div>
                  ))
              }
            </List>
          </div>
          {
            /*
            <div className="has-text-centered">
            <Link className="button has-crown is-medium" href={'/entries/finalist'}>
              <span>ファイナリスト一覧</span>
            </Link>
          </div>
             */
          }
          <div className="has-text-centered">
            <Link className="button has-crown is-medium" href={'/entries'}>
              <span>他の候補者を見る</span>
            </Link>
          </div>
        </div>
      </section>
      {/*
        Event
      */}
      <section id="event" className="section">
        <div className="container" style={{maxWidth: 780}}>
          <div className="mb-6">
            <h1 className="title as-section-title">Event</h1>
            <h2 className="subtitle">イベント情報</h2>
          </div>
          <p className="has-text-centered is-size-6-mobile is-size-5 has-text-weight-bold mb-3">MISS OF MISS CAMPUS
            QUEEN CONTEST 2022 supported by ACNAL</p>
          <p className="has-text-centered is-size-6-mobile is-size-5 has-text-weight-bold mb-3">MR OF MR CAMPUS CONTEST 2022 supported by ACNAL
          </p>

          <div className="is-margin-bottom-3 mt-6"><h1
            className="title is-gray is-size-6 has-text-weight-bold as-section-title">MC</h1><h2
            className="subtitle"/></div>

          <div className="columns is-centered is-mobile is-margin-bottom-6">
            <div className="column is-8-tablet is-10-mobile has-text-centered"><p
              className="is-size-6 is-margin-bottom-1"><span className="is-size-5 has-text-weight-bold">NON STYLE</span></p>
              <div id="guest" className="card">
                <div className="card-image">
                  <figure className="image is-max-480x480"><img src="/image/nonstyle.jpg" width="320"/></figure>
                </div>
                <div className="card-content">
                  <div className="content has-text-left is-size-7-mobile">
                    またMR OF MR CAMPUS CONTEST という若者達の人生の変わる瞬間に立ち合えて光栄です。 コロナ禍で満足いくような学生時代が送れなかった方も多いと思います。 そんな中で、今回エントリーされている方々、キラキラと輝く夢を見させてくれるはずです。 最高の笑顔のサポートが出来るように頑張ります！！（井上裕介）<br /><br />
                    今年も始まりますMR OF MR CAMPUS CONTEST。本当にみんな努力に努力を重ね、頂点を目指しているので感動とパワーとかわいさに溢れています。みなさんお楽しみに。僕は井上がこそこそと電話番号渡したりしないかだけを見張っておきます。（石田明）
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="columns is-centered is-mobile is-margin-bottom-6">
            <div className="column is-8-tablet is-10-mobile has-text-centered"><p
              className="is-size-6 is-margin-bottom-1"><span className="is-size-5 has-text-weight-bold">鷲見 玲奈</span><br
              className="is-hidden-tablet"/><span className="is-size-7-mobile">（フリーアナウンサー）</span></p>
              <div id="guest" className="card">
                <div className="card-image">
                  <figure className="image is-max-480x480"><img src="/image/sumi.jpg" width="320"/></figure>
                </div>

                <div className="card-content">

                  <div className="content has-text-left is-size-7-mobile">
                    今回初めてMCを務めさせて頂きますが、このような素敵な機会を頂きたいへん嬉しく思います。 <br />
                    MISS OF MISS CAMPUS QUEEN CONTESTは、学生時代から見ていましたが、とても華やかでキラキラしている舞台という印象でした。<br />
                    今回エントリーされる皆さんの思いをしっかりと受け取りながら、一人一人の個性が輝く瞬間を見届けたいと思います。
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="is-margin-bottom-3 mt-6"><h1
            className="title is-gray is-size-6 has-text-weight-bold as-section-title">ゲスト</h1><h2
            className="subtitle"/></div>

          <div className="columns is-centered is-mobile is-margin-bottom-6">
            <div className="column is-8-tablet is-10-mobile has-text-centered"><p
              className="is-size-6 is-margin-bottom-1"><span className="is-size-5 has-text-weight-bold">山賀 琴子</span><br
              className="is-hidden-tablet"/><span className="is-size-7-mobile">（モデル・ブランドディレクター）</span><br/><br
              className="is-hidden-mobile"/><span className="is-size-7-mobile">MISS OF MISS CAMPUS QUEEN CONTEST <br
              className="is-hidden-tablet"/>2016 グランプリ</span><br/><span
              className="is-size-7-mobile">ミス青山コンテスト2015 グランプリ</span></p>
              <div id="guest" className="card">
                <div className="card-image">
                  <figure className="image is-max-480x480"><img src="/image/guest.jpg" width="320"/></figure>
                </div>
                <div className="card-content">
                  <div className="content has-text-left is-size-7-mobile">MISS OF MISS CAMPUS QUEEN
                    CONTESTに参加してから5年が経ち、昨年に引き続き、今年もまた携わらせていただくことができとても嬉しく思います。<br/>
                    エントリーされた皆様の気持ちを想うと、今から私も胸が高まってしまいます。出場者の皆様にお会いできることを、そして皆様と素晴らしい時間を共有できることを心より楽しみにしております。
                  </div>
                </div>
              </div>
            </div>
          </div>
          <table className="table is-fullwidth as-information mt-6">
            <tbody>
            <tr>
              <th>日時</th>
              <td>2022年3月9日(水) <br />
              開場：15時15分　開演：16時00分</td>
            </tr>
            <tr>
              <th>会場</th>
              <td>
                <a
                  className="has-text-grey-dark"
                  style={{ textDecoration: 'underline' }}
                  href="https://www.ex-theater.com/"
                  target="_blank"
                >
                  EX THEATER ROPPONGI
                </a>{' '}
                (東京都港区西麻布1-2-9)
              </td>
            </tr>
            <tr>
              <th>チケット料金</th>
              <td>前売り券：¥3,000-　当日券：¥3,500-<br />
              （ドリンク代別）</td>
            </tr>
            <tr>
              <th>販売期間</th>
              <td>
                2022年2月14日12:00　〜　2022年3月9日15:00<br />
                <a href="https://eplus.jp/sf/detail/3572600001-P0030001" target="_blank" className="button is-outlined is-link">チケット購入</a>
              </td>
            </tr>
            <tr>
              <th>主催</th>
              <td>学生団体MARKS / <wbr />株式会社エイジ・エンタテインメント</td>
            </tr>
            <tr>
              <th>企画制作</th>
              <td>
                <a href="https://miscolle.com/" className="has-text-grey-dark"
                   style={{ textDecoration: 'underline' }} target="_blank">
                  MISCOLLE
                </a>
              </td>
            </tr>
            <tr>
              <th>日程</th>
              <td>
                全国予選：2022/2/14(月)～2022/2/20(日)<br />
                <br />
                決勝：2022/2/28(月)～2022/3/6(日)<br />
                <br />
                表彰式：2022/3/9(水)
              </td>
            </tr>
            <tr>
              <th>特別協賛</th>
              <td>
                <a href="https://acnal.jp/" target="_blank">
                  <figure id="acnal">
                    <ACNAL height={90}/>
                  </figure>
                </a>
              </td>
            </tr>
            <tr>
              <th>特別協力</th>
              <td>
                <div id="sponsors" className="images">
                  {/*
                                    <a href="http://anatore.fujitv.co.jp/" target="_blank">
                    <figure id="anatore" className="image mt-0 mb-5">
                      <img src="/image/anatore.png" />
                    </figure>
                  </a>
                  <a href="https://www.tv-asahi-ask.co.jp/" target="_blank">
                    <figure id="ask" className="image mt-0 mb-6">
                      <img src="/image/ask.png" />
                    </figure>
                  </a>
                     <a href="https://ray-web.jp/" target="_blank">
                    <figure id="ray" className="image mt-0 mb-6">
                      <Ray height={45} />
                    </figure>
                  </a>
                  */}

                  <a href="https://anatore.fujitv.co.jp/" target="_blank">
                    <figure id="anatore" className="image mt-0 mb-5">
                      <img src="/image/anatore.png" />
                    </figure>
                  </a>
                  <a href="https://www.tv-asahi-ask.co.jp/" target="_blank">
                    <figure id="ask" className="image mt-0 mb-6">
                      <img src="/image/ask.png" />
                    </figure>
                  </a>
                  <a href="https://cancam.jp/" target="_blank">
                    <figure id="cancam" className="image mt-0 mb-6">
                      <CanCam height={50}/>
                    </figure>
                  </a>
                  <a href="https://nonno.hpplus.jp/" target="_blank">
                    <figure id="nonno" className="image mt-0 mb-6">
                      <img src="/image/nonno.png"/>
                    </figure>
                  </a>
                  <a href="https://www.mensnonno.jp/" target="_blank">
                    <figure id="mensnonno" className="image mt-0 mb-6">
                      <img src="/image/mensnonno.png"/>
                    </figure>
                  </a>
                  <a href="https://mdpr.jp/" target="_blank">
                    <figure id="modelpress" className="image mt-0 mt-6">
                      <img src="/image/modelpress.png" />
                    </figure>
                  </a>
                </div>
              </td>
            </tr>
            {/*
            <tr>
              <th className="is-narrow">協賛</th>
              <td>
                <div className="images">
                 <a href="https://www.rizeclinic.com/" target="_blank">
                    <figure id="rize" className="image mt-0 mb-6">
                      <img src="/image/rize.png" height={90} />
                    </figure>
                  </a>
                  <a href="https://www.mens-rize.com/" target="_blank">
                    <figure id="mensrize" className="image mt-0 mb-6">
                      <img src="/image/mensrize.png" height={90} />
                    </figure>
                  </a>
                  <a href="https://www.mysta.tv/" target="_blank">
                    <figure id="mysta" className="image mt-0 mb-6">
                      <img src="/image/mysta.png" height={40} />
                    </figure>
                  </a>
                </div>
              </td>
            </tr>
            */}

            </tbody>
          </table>
        </div>
      </section>
      {/*
        Schedule
      */}
      <section id="schedule" className="section has-background-gradient is-mrofmr">
        <div className="container">
          <div className="mb-6">
            <h1 className="title as-section-title">Schedule</h1>
            <h2 className="subtitle mb-3">日本一のミスターキャンパスが決まるまで</h2>
            <p className="is-size-7 has-text-white has-text-centered">
              ※ スケジュールは予告なく変更されることがあります。
            </p>
          </div>
          <Schedule />
        </div>
      </section>
      {/*
        Media
      */}
      <section id="media" className="section">
        <div className="container" style={{maxWidth: 780}}>
          <div className="mb-6">
            <h1 className="title as-section-title">Media</h1>
            <h2 className="subtitle">掲載メディア</h2>
          </div>
          <MediaBoxes>
            <div style={{maxWidth: 250}}>
              <MediaBox
                href="https://mdpr.jp/news/detail/2429581"
                src="https://img-mdpr.freetls.fastly.net/article/6EXu/nm/6EXu-G98K3E7kIn0XzuUj5z88f7cEoVvtNLAloIXMK4.jpg?width=700&disable=upscale&auto=webp"
                title="[モデルプレス]日本一のミスターキャンパスを決めるコンテスト、全国予選出場者決定＜MR OF MR CAMPUS CONTEST 2021＞"
                media="modelpress"
                datetext="2021.02.08"
                icon="https://pbs.twimg.com/profile_images/1156411788985978880/vJyWfh5H_400x400.png"
              />
            </div>
            <div style={{maxWidth: 250}}>
              <MediaBox
                href="https://mdpr.jp/news/2394179"
                src="https://img-mdpr.freetls.fastly.net/article/IcxX/nm/IcxX3Ytplb1051HHGDkvgBzZuEGdue3Eixym8CDLFI8.jpg?width=700&disable=upscale&auto=webp"
                title="[モデルプレス]日本一のミスターキャンパスを決めるコンテスト、第1弾出場者発表＜MR OF MR CAMPUS CONTEST 2021＞"
                media="modelpress"
                datetext="2021.01.15"
                icon="https://pbs.twimg.com/profile_images/1156411788985978880/vJyWfh5H_400x400.png"
              />
            </div>
            <div style={{maxWidth: 250}}>
              <MediaBox
                href="https://thetv.jp/news/detail/1017515/"
                src="https://thetv.jp/i/nw/1017515/10151559.jpg?w=615"
                title='[ザ・テレビジョン]全国のミスターキャンパスの中から"日本一のイケメン大学生"を決定するコンテスト、第一弾出場者発表'
                media="ザ・テレビジョン"
                datetext="2021.01.15"
                icon="https://pbs.twimg.com/profile_images/842775920091975680/7igeN6FE_400x400.jpg"
              />
            </div>
          </MediaBoxes>
        </div>
      </section>

      {/*
        History
      */}
      <section id="history" className="section is-mrofmr">
        <div className="container">
          <div className="mb-6">
            <h1 className="title as-section-title">History</h1>
            <h2 className="subtitle">ミスターオブミスターの歴史</h2>
          </div>
          <History json={history}/>
        </div>
      </section>
      <nav className="navbar">
        <div className="columns is-variable is-4 is-mobile is-centered is-vcentered">
          <div className="column is-narrow">
            <figure className="image">
              <Logo2021 width={120}/>
            </figure>
          </div>
          <div className="column is-narrow">
            <a
              href="https://2021.mrofmr.jp"
              target="_blank"
              rel="noopener noreferrer"
              className="button is-size-7-mobile is-black has-text-weight-bold"
            >
              <span>昨年の様子はこちら</span>
              <span className="icon">
                <i className="fas fa-chevron-right"/>
              </span>
            </a>
          </div>
        </div>
      </nav>
      <footer className="footer has-background-black is-padding-bottom-3">
        <div className="content has-text-centered">
          <Logo width={150}/>
          <p className="is-size-7 mt-3">Copyright © 2021 株式会社エイジ・エンタテインメント All Rights Reserved.</p>
        </div>
        <a className="button is-size-7 is-light is-dark" href="mailto:inquiry@missofmiss.jp">
          <span className="icon">
            <i className="far fa-envelope"/>
          </span>
          <span>お問い合わせ</span>
        </a>
      </footer>
    </div>
  );
};
